// import React, {useState,useEffect} from 'react';
// import Breadcrumbs from "../breadcrumbs";
// import ContentLoader from 'react-content-loader'
// import {connect} from "react-redux"
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { EditorState, ContentState, convertToRaw } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { navigate } from "gatsby"
// import { graphql, useStaticQuery } from 'gatsby';
// import NoveltyCard from '../novelty-card';
// import { getTextShort, getDateTime, getFeatured } from '../../helpers/helper.novelty'
// import ReCAPTCHA from "react-google-recaptcha";


// const Main = ({loading,loading_novelties,novelties,novelty}) => {
//     const { realEstate } = useStaticQuery(graphql`
//       query {
//           realEstate {
//               name
//               global_email
//               global_phone
//               keys {
//                 captcha
//               }
//               social{
//                 facebook
//                 instagram
//               }
//               branch_office{
//                 name
//                 address
//                 city
//                 region
//                 broker_id
//                 contact {
//                   phone
//                   mail
//                   whatsapp
//                 }
//               }
//               sections {
//                     services{
//                         title
//                         subtitle
//                         paragraph
//                     }
//                     footer {
//                         title_contact
//                         button_contact {
//                             value
//                             link
//                         }
//                         title_office
//                         title_social
//                         title_newsletter
//                     }
//               }
//           }
//     }`)

//     return(
//         <section class="tasaciones-main">
//             <div class="container">
//                 <div class="row center align-items-center">
//                     <div class="col-12">
//                         <div class="row content-main align-items-start">
//                             <div class="col-12 mt-lg-auto">
//                                 <h2>Conocé el verdadero <br/>valor de tu propiedad.</h2>
//                             </div>
//                             <div class="col-lg-6"> 
//                                 <p class="mt-5">
//                                     Completá el siguiente formulario con los datos <br class="d-none  d-lg-block"/> 
//                                     básicos de tu propiedad y un agente especializado  <br class="d-none  d-lg-block"/> 
//                                     se pondrá en contacto a la brevedad para  <br class="d-none  d-lg-block"/> 
//                                     comenzar a tasar tu propiedad. 
//                                 </p>
//                                 <div class="content-contact d-none d-lg-block">
//                                     <a class="mailto mb-4 d-block" href="mailto:info@carlosbezruk.com.ar"><h5>info@carlosbezruk.com.ar</h5></a>
//                                     <div class="whatsapp my-3">  
//                                         <h5>Whatsapp</h5>
//                                         <div class="d-none d-lg-block">
//                                             <p><span>Av. Libertador</span> <a href="https://web.whatsapp.com/send?text=%C2%A1Hola!&phone=+5491155291387">11 5529-1387</a></p>
//                                             <p><span>Av. S. A. Fleming</span> <a href="https://web.whatsapp.com/send?text=%C2%A1Hola!&phone=+5491127845344">11 2784-5344</a></p>   
//                                         </div>
//                                         <div class="px-0 d-block d-lg-none">
//                                             <p><span>Av. Libertador</span> <a href="https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=+5491155291387">11 5529-1387</a></p>
//                                             <p><span>Av. S. A. Fleming</span> <a href="https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=+5491127845344">11 2784-5344</a></p>
//                                         </div>
//                                     </div>
//                                 </div>
                                
//                             </div>
//                             <div class="col-lg-6">
//                                 <form class="row content-form ajax-form">
//                                     <input type="hidden" name="action" value="tasaciones"/>
//                                     <div class="col-lg-12 mb-lg-3">
//                                         <input type="text" name="name" placeholder="Nombre y apellido *" required/>
//                                     </div>
//                                     <div class="col-lg-12 mb-lg-3">
//                                         <input type="email" name="email" placeholder="Mail *" required/>
//                                     </div>
//                                     <div class="col-6 pr-1 pr-lg-auto mb-lg-3">
//                                         <input type="tel" name="phone" placeholder="Teléfono *" required/>
//                                     </div>
//                                     <div class="col-6 pl-1 pl-lg-auto mb-lg-3">
//                                         <input type="text" name="location" placeholder="Localidad de la propiedad *" required/>
//                                     </div>
//                                     <div class="col-lg-6 pr-lg-1 mb-lg-3">
//                                         <select name="operation">
//                                             <option value="none">Operación</option>
//                                             <option value="Venta">Venta</option>
//                                             <option value="Alquiler">Alquiler</option>
//                                             <option value="Alquiler Temporario">Alquiler Temporario</option>
//                                         </select>
//                                         <i></i>
//                                     </div>
//                                     <div class="col-lg-6 pl-lg-1 mb-lg-3">
//                                         <select name="type">
//                                             <option value="none">Tipo de propiedad</option>
                                            
//                                         </select>
//                                         <i></i>

//                                     </div>
//                                     <div class="col-lg-12 mb-lg-3">
//                                         <textarea name="message" cols="30" rows="5" placeholder="Mensaje *" required></textarea>
//                                     </div>
//                                     <div class="col-lg-5 mt-lg-2">
//                                         <div className="div_reCaptcha">
//                                             {
//                                             realEstate?.keys?.captcha ? 
//                                             <ReCAPTCHA
//                                             sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
//                                             onChange ={() => setDisabled(false)}
//                                             /> 
//                                             : 
//                                             '' 
//                                             }

//                                         </div>
//                                         <input type="submit" value="ENVIAR" class="btn-global btn-secondary submit mt-4" id="submit-info" disabled="disabled"/>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// } 

// export default connect(state => ({
//     loading_novelties: state.novelties.loading_novelties,
//     loading: state.novelties.loading,
//     novelties: state.novelties.novelties,
//     novelty: state.novelties.novelty,
//   }),null)(Main);
import React, { useEffect } from 'react'
import Formulario from '../Servicios/form'
import Breadcrumbs from "../breadcrumbs";
import { graphql, useStaticQuery } from 'gatsby';
import { connect } from "react-redux";
import { useLocation } from "@reach/router";
import {getBrText} from '../../helpers/helper.rendering'

const Main = ({actions}) => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
              }
              keys{
                captcha
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                        form
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        button_contact {
                            link
                            value
                        }
                    }
              }
          }
    }`)

    const location = useLocation()

    useEffect(() => {
        if(location.pathname.includes('/tasaciones')) {
            checkActionsTop()
        }
    },[location])

    const checkActionsTop = () => {
        if (actions && actions.length > 0) {
            const filteredActions = actions.filter(a => a.small_type === "VEN" || a.small_type === "IEN" || a.small_type === "SIE").map(a => [a.section, a.small_type].join())
            // console.log(filteredActions); - // ['Tasaciones,VEN']
            if (filteredActions.length > 0) {
                const filteredbyPage = filteredActions.filter(p => p.includes("Tasaciones"))
                return (filteredbyPage.length > 0)
                    ? ((filteredbyPage[0].split(',')[0] === "Tasaciones") || null) : null
            }
        } else {
            return null
        }
    }

    return realEstate.sections &&
      realEstate.sections.services.form ? (
        <section class={`tasaciones-main ${checkActionsTop() ? "no-space-top" :  ""}`}>
            <div class="container px-3">
                <div class="row center center align-items-center">
                    <div class="col-12">
                        <div class="row content-main align-items-start">
                            <div className="col-12 mt-lg-5 mt-2">
                               <Breadcrumbs
                                   props={[
                                   { name: realEstate.name, route: "/", location: "" },
                                   { name: "Tasaciones", route:   "", location: ""},
                                   ]}
                               />
                           </div>
                           <div class="col-12 col-lg-4  text-right align-items-center">   
                                <h2 class="mb-5">{realEstate.sections.services.title}</h2> 
                                <h5>
                                    {getBrText(realEstate.sections.services.paragraph).map((element) => (
                                        <>
                                            {element}
                                            <br />
                                        </>
                                    ))}
                                </h5>
                                <div class="content-contact d-none d-lg-block">
                                    {/* <a class="mailto mb-4 d-block" href="mailto:info@carlosbezruk.com.ar"><h5>info@carlosbezruk.com.ar</h5></a> */}
                                    <a class="mailto mb-4 d-block hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}><h5>{realEstate.global_email}</h5></a>
                                    <div class="whatsapp my-3">
                                        <h5>Whatsapp</h5>
                                        {realEstate.branch_office.map((item,index) => (
                                            <div class="d-block">
                                                {/* <p><span>{item.address}</span><a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + item.contact.whatsapp} target="_blank">{item.contact.whatsapp}</a></p> */}
                                                { item.contact.whatsapp ? <p><span>{item.name}</span> <a href="https://web.whatsapp.com/send?text=%C2%A1Hola!&phone=+5491127845344">{ item.contact.whatsapp }</a></p> : "" }   

                                            </div>
                                        ))}   
                                    </div>
                                </div>
                                    
                                
                            </div>
                            <div class="col-12 col-lg-7 offset-lg-1">
                                <Formulario />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default connect(state => ({
    actions: state.actions.actions,
}),null)(Main);