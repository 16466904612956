import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Main from '../components/Tasaciones/main'
import BannerTop from "../components/Global/Modules/MediaPush/BannerTop"

const IndexPage = () => (
  <Layout>
    <Seo title="Tasaciones" description="Sección de contacto" />
    <BannerTop page="tasaciones"/>
    <Main />
  </Layout>
)

export default IndexPage
